import React from "react";
import { Box, Typography, IconButton } from "@mui/material";
import SAMPLEDATA from "../../constants/text";
import styled from "@emotion/styled";
import { Facebook, LinkedIn, Twitter } from "@mui/icons-material";
import TermsAndServiceModal from "../../common/modal/TermsAndServiceModal";
import theme from "../../constants/Theme";

const FooterComp = styled.div(({ theme }) => {
  return {
    display: "flex",
    justifyContent: "center",

    [theme.breakpoints.up("md")]: {
      padding: "8px 48px",
    },
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(2),
    },
  };
});
const FooterContent = styled.div(({ theme }) => {
  return {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      justifyContent: "space-between",
      flexDirection: "row",
      marginBottom: "8px",
    },
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column-reverse",
      "& > div": {
        marginBottom: "16px",
      },
    },
  };
});

const SocialIcons = styled.div(({ theme }) => {
  return {
    display: "flex",
    alignItems: "center",
    gap: "16px",
  };
});

const Links = styled.div(({ theme }) => {
  return {
    display: "flex",
    alignItems: "center",
    gap: "16px",
    a: {
      fontSize: "14px",
      textDecoration: "none",
      fontFamily: '"RobotoRegular", "Helvetica", "Arial", sans-serif',
      color: "white",
      "&:hover, &:focus": {
        textDecoration: "underline",
      },
    },
  };
});

export default function Footer(props) {
  return (
    <FooterComp>
      <Box sx={{ maxWidth: theme.breakpoints.values.xl, width: 1 }}>
        <FooterContent>
          <Box>
            <Typography
              variant="body_small"
              color={theme.palette.secondary.white}
            >
              © 2022 StartupOS LLC. All rights reserved
            </Typography>
          </Box>
          <Box>
            <TermsAndServiceModal color="primary" />
          </Box>

          <SocialIcons>
            <IconButton
              aria-label="Facebook"
              variant="outlined"
              color="secondary"
              onClick={() => window.open(SAMPLEDATA.FACEBOOKURL, "_blank")}
            >
              <Facebook />
            </IconButton>
            <IconButton
              aria-label="Twitter"
              variant="outlined"
              color="secondary"
              onClick={() => window.open(SAMPLEDATA.TWITTERURL, "_blank")}
            >
              <Twitter />
            </IconButton>
            <IconButton
              aria-label="LinkedIn"
              variant="outlined"
              color="secondary"
              onClick={() => window.open(SAMPLEDATA.LINKEDINURL, "_blank")}
            >
              <LinkedIn />
            </IconButton>
          </SocialIcons>
        </FooterContent>
      </Box>
    </FooterComp>
  );
}
