import { createTheme } from "@mui/material/styles";

export const sosPrimary = {
  900: "#492b76",
  800: "#593587",
  700: "#633b90",
  600: "#6e439a",
  500: "#7649a0",
  400: "#8963ae",
  300: "#9e7fbd",
  200: "#b9a4d0",
  100: "#d4c8e2",
  50: "#eee9f3",
};

export const themePalette = createTheme({
  palette: {
    type: "light",
    primary: {
      main: sosPrimary[500],
      gradient: "linear-gradient(180deg, #592C82 6.68%, #B655CC 23.14%, #FFFFFF 29.12%)",
    },
    secondary: {
      main: "#4B454E",
      light: "#7C757F",
      white: "#FFFFFF",
      stroke1:"#E6DFEC",
      red: "#F45D48",
    },
    text: {
      primary: "#1D1B1E",
      secondary: "#4B454E",
    },
    containers: {
      fabModalShadow: "0px 5px 40px rgba(0, 0, 0, 0.16)",
      innerModalShadow: "0px 1px 2px rgb(0 0 0 / 10%), 0px 4px 15px rgb(0 0 0 / 10%)",
    },
  },
});
