import * as React from "react";
import parse from "html-react-parser";
import {
  Box,
  IconButton,
  Typography,
  Link,
  Divider,
  DialogTitle,
  Grid,
  DialogActions,
  Button,
} from "@mui/material";
import SAMPLEDATA from "../../constants/text";
import { Close } from "@mui/icons-material";
import theme from "../../constants/Theme";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { useHistory, useLocation } from "react-router";

export default function TermsAndServiceModal(props) {
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");
  const [content, setContent] = React.useState("");
  const [title, setTitle] = React.useState();
  const location = useLocation();
  const history = useHistory();

  const pathname = location.pathname;
  // manage currentUrl in state.
  const [currentUrl, setCurrentUrl] = React.useState(pathname);
  React.useEffect(() => {
    setCurrentUrl(pathname);
  }, [pathname]);

  const handleClickOpen = scrollType => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
    history.push("/");
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  React.useEffect(() => {
    if (location && location.pathname == "/terms") {
      handleContent(SAMPLEDATA.TERMSANDSERVICETITLE);
    } else if (location && location.pathname == "/privacy") {
      handleContent(SAMPLEDATA.PRIVACYPOLICYTITLE);
    }
  }, [currentUrl]);

  const handleContent = type => {
    handleClickOpen("paper");
    setTitle(type);
    if (type === SAMPLEDATA.TERMSANDSERVICETITLE) {
      setContent(SAMPLEDATA.TERMSANDSERVICETEXT);
    }
    if (type === SAMPLEDATA.PRIVACYPOLICYTITLE) {
      setContent(SAMPLEDATA.PRIVACYPOLICYTEXT);
    }
  };

  return (
    <>
      <Box>
        <Link
          onClick={() => history.push("/terms")}
          sx={{
            color: theme.palette.secondary.white,
            cursor: "pointer",
          }}
        >
          Terms
        </Link>
        <Link
          onClick={() => history.push("/privacy")}
          sx={{
            color: theme.palette.secondary.white,
            cursor: "pointer",
            ml: 5,
          }}
        >
          Privacy Policy
        </Link>
      </Box>
      <Typography>
        <Dialog
          open={open}
          onClose={handleClose}
          scroll={"body"}
          fullWidth
          maxWidth={"xl"}
        >
          <DialogTitle sx={{ background: `${theme.palette.primary.main}` }}>
            <Grid
              container
              justifyContent={"space-between"}
              alignItems="center"
            >
              <Grid item>
                <Typography variant="h3" color={"white"}>
                  {title}
                </Typography>
              </Grid>
              <Grid item>
                <IconButton sx={{ p: 3 }} onClick={handleClose}>
                  <Close
                    sx={{
                      color: theme.palette.secondary.white,
                    }}
                  />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {parse(content, {
                replace: domNode => {
                  if (domNode.attribs && domNode.attribs.id === "privacyUrl") {
                    return (
                      <Link
                        sx={{
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          handleContent(SAMPLEDATA.PRIVACYPOLICYTITLE)
                        }
                      >
                        Privacy Policy
                      </Link>
                    );
                  }
                  if (domNode.attribs && domNode.attribs.id === "termsUrl") {
                    return (
                      <Link
                        sx={{
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          handleContent(SAMPLEDATA.TERMSANDSERVICETITLE)
                        }
                      >
                        Terms and Services
                      </Link>
                    );
                  }
                },
              })}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={handleClose}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Typography>
    </>
  );
}
